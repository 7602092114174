import React from 'react';
import {NavLink} from 'react-router-dom';
import ProfileIcon from "../../Profile/ProfileIcon/ProfileIcon";
import classes from './Header.module.css';
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !== null
    }
}

const header = ( props ) => {
    const loginLink = !props.isAuthenticated ? <NavLink to="/auth">Log in</NavLink> : <NavLink to="/logout">Log out </NavLink>;
    const profileIcon = props.isAuthenticated ? <ProfileIcon/> : null;
    return (
        <header className={classes.Header}>
            <div className={classes.Logo}>
                <NavLink to="/">
                    Damazla
                </NavLink>
            </div>
            
            {loginLink}
            {profileIcon}
        </header>
    );
};

export default connect(mapStateToProps)(header);