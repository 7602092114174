import React from "react";
import classes from "./Layout.module.css";
import Header from "../../components/Navigation/Header/Header";

const layout = (props) => {
    return (
        <div className={classes.Layout}>
            <Header/>
            <main className={classes.Content}>  
                {props.children}
            </main>
        </div>
    );
}

export default layout;