import React, { Suspense } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import Orders from "./containers/Orders/Orders";
import Layout from "./hoc/Layout/Layout";
import Logout from "./containers/Auth/Logout/Logout";

import "./App.css";

const Auth = React.lazy(() => {
  return import("./containers/Auth/Auth");
});

const App = () => {
  return (
    <Layout>
      <Suspense fallback={<p>Loading...</p>}>
        <Routes>
          <Route path="/auth" element={<Auth />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/profile" element={null} />
          <Route path="/" exact element={<Orders />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default App;
