import React from 'react';
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import classes from './ProfileIcon.module.css';

const profileIcon = (props) => {
    if (!props.isAuthenticated) {
        return (
            <Link to="/profile">
                <span className={`material-icons ${classes.ProfileIcon}`}>
                    person_outline
                </span>
            </Link>
        );
    } else return null;
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        isAuthenticated: state.isAuthenticated
    }
}

export default connect(mapStateToProps)(profileIcon)